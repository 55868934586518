<template>
    <div id="login-page" class="h-100"
         :style="[is_mobile ? {background: 'url(' + $store.getters['display_option/background_login'] + ') no-repeat center fixed'} : {}]">

        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col lg="3" md="4" cols="12" class="col-mobile-login display-flex-content-center">
                <v-card :class="is_mobile ? 'max-width-300' : ''" class="border-none pa-2" outlined elevation="0">
                    <login-account-component v-if="!show_password_reset && show_login_component"
                                             v-on:showPasswordReset="show_password_reset = !show_password_reset"
                                             v-on:showAccountConfirmation="showAccountConfirmation"/>

                    <account-confirmation-component v-if="show_account_confirmation" :sign_up_link="sign_up_link"
                                                    :page_account_activate="page_account_activate"
                                                    v-on:showLoginComponent="showLoginComponent"/>

                    <reset-password-component v-if="show_password_reset"
                                              v-on:showPasswordReset="show_password_reset = !show_password_reset"/>

                    <new-password-component v-if="!show_login_component && !show_account_confirmation"
                                            :uid="uid" :code="code"
                                            v-on:showLoginComponent="showLoginComponent"/>
                </v-card>
            </v-col>

            <v-col lg="9" md="8" class="pa-0" v-if="!is_mobile">
                <v-img :src="$store.getters['display_option/background_login']" class="img-background-fullscreen"/>
            </v-col>
        </v-row>

        <p v-if="!is_mobile" class="display-version version-login-desktop">{{ $t('parameter_page.version') }}
            {{ app_version }}</p>
    </div>
</template>

<script>
import LoginAccountComponent from "@/components/Login/LoginAccountComponent";
import ResetPasswordComponent from "@/components/Login/ResetPasswordComponent";
import NewPasswordComponent from "@/components/Login/NewPasswordComponent";
import AccountConfirmationComponent from "@/components/Login/AccountConfirmationComponent";
import UserClientRepository from "@/repositories/UserClientRepository";

export default {
    name: "LoginPage",
    data: function () {
        return {
            show_password_reset: false,
            show_login_component: true,
            show_account_confirmation: false,
            uid: '',
            code: '',
            sign_up_link: '',
            page_account_activate: false
        }
    },
    components: {
        AccountConfirmationComponent, NewPasswordComponent, LoginAccountComponent, ResetPasswordComponent
    },
    methods: {
        showLoginComponent() {
            this.page_account_activate = false;
            this.show_account_confirmation = false;
            this.$router.replace({'query': null});
            this.show_login_component = true;
        },

        showAccountConfirmation() {
            this.show_login_component = false;
            this.show_account_confirmation = true;
        },

        loginAsUserAdmin(token) {
            UserClientRepository.getCheckTokenUserClient(token).then((success) => {
                this.$auth.token(null, success.data.data);
                this.$auth.fetch({}).then((result) => {
                    this.setAllInformationAfterConnect(this.$auth.user().id_professional, result.data.data);
                });
            });
        }
    },
    created() {
        this.uid = this.$route.query.uid;
        this.code = this.$route.query.code;
        this.sign_up_link = this.$route.query.sign_up_link;

        if (this.code !== undefined || this.uid !== undefined) {
            this.show_login_component = false;
        } else if (this.sign_up_link !== undefined) {
            this.show_login_component = false;
            this.show_account_confirmation = true;
            this.page_account_activate = true;
        }

        if (this.$route.query.token) {
            this.$store.dispatch('general/setGlobalLoader', true);
            this.loginAsUserAdmin(this.$route.query.token);
        } else {
            document.getElementById("favicon").href = this.$store.getters['display_option/display_option'].src_favicon;
        }
    }
}
</script>

<style lang="scss">
.border-login-choose {
    border-bottom: var(--v-primary-base) 3px solid;
    font-weight: bold;
    color: black;
}

.img-background-fullscreen {
    height: 100vh
}

.version-login-desktop {
    position: absolute;
    right: 15px;
    bottom: 15px;
}
</style>
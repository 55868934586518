<template>
    <div id="login-component">
        <v-card-title class="justify-center text-center">
            <v-img :src="$store.getters['display_option/logo']" alt="Logo" class="mb-4"
                   contain max-height="70" width="100%"
            />

            <strong>{{ $t('login_account_component.welcome') }}</strong>
        </v-card-title>

        <v-card-text class="pb-0">
            <v-row v-if="$store.getters['display_option/display_option'].authorize_subscription"
                   class="text-center font-12 mb-4 ml-2 mr-2 pointer">
                <v-col :class="value_selected === 1 ? 'border-login-choose' : ''"
                       @click="value_selected = 1"
                >
                    <span>{{ $t('login_account_component.identify_myself') }}</span>
                </v-col>

                <v-col :class="value_selected === 2 ? 'border-login-choose' : ''"
                       @click="value_selected = 2"
                >
                    {{ $t('login_account_component.create_account') }}
                </v-col>
            </v-row>

            <v-text-field v-model="email" :label="$t('login_account_component.email')"
                :rules="email_rules" filled data-cy="login-email"
            />

            <v-expand-transition>
                <v-text-field v-show="value_selected === 2" v-model="last_name"
                    :label="$t('login_account_component.last_name')"
                    :rules="[l => !!l || $t('login_account_component.last_name_required')]"
                    filled
                />
            </v-expand-transition>

            <v-expand-transition>
                <v-text-field v-show="value_selected === 2" v-model="first_name"
                    :label="$t('login_account_component.first_name')"
                    :rules="[l => !!l || $t('login_account_component.first_name_required')]"
                    filled
                />
            </v-expand-transition>

            <v-text-field
                v-model="password"
                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :hide-details="value_selected === 1 ? 'auto' : false"
                :label="$t('login_account_component.password')"
                :rules="value_selected === 2 ? password_rules :[ l => !!l || $t('login_account_component.password_required')]"
                :type="show_password ? 'text' : 'password'"
                filled @click:append="show_password = !show_password"
                @keypress.enter="value_selected === 1 ? login() : null"
                data-cy="login-password"
            />

            <v-expand-transition>
                <v-text-field
                    v-show="value_selected === 2"
                    v-model="confirm_password"
                    :append-icon="show_confirm_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('login_account_component.confirm_password')"
                    :rules="password_confirm_rules"
                    :type="show_confirm_password ? 'text' : 'password'"
                    filled
                    hide-details="auto" @click:append="show_confirm_password = !show_confirm_password"
                    @keypress.enter="value_selected === 1 ? null : register()"
                />
            </v-expand-transition>

            <span v-if="value_selected === 1"
                  class="font-12 text-decoration-underline primary--text pointer" @click="$emit('showPasswordReset')">
                {{ $t('login_account_component.forget_password') }}
            </span>

            <v-checkbox v-if="value_selected === 1" v-model="remember_me">
                <template v-slot:label>
                    <span class="font-12">{{ $t('login_account_component.stay_connected') }}</span>
                </template>
            </v-checkbox>
        </v-card-text>

        <v-card-actions :class="value_selected === 2 ? 'mt-6' : ''" class="ml-2 mr-2">
            <v-btn :disabled="!form_valid" :loading="loading_login" block large
                   color="primary font-weight-bold btn-secondary-loader"
                   @click="value_selected === 1 ? login() : register()"
            >
                <v-progress-circular v-if="loading_register_account" class="mr-2"
                                     color="secondary" indeterminate size="20" width="2"
                />
                <span :class="form_valid ? 'secondary--text font-weight-bold' : 'font-weight-bold'">{{
                        value_selected === 1 ? $t('login_account_component.login') : $t('login_account_component.subscribe')
                    }}</span>
            </v-btn>
        </v-card-actions>
        <p v-if="is_mobile" class="text-center display-version version-login-mobile">Version {{ app_version }}</p>
    </div>
</template>

<script>
import UserClientRepository from "@/repositories/UserClientRepository";

export default {
    name: "LoginAccountComponent",
    data: function () {
        return {
            value_selected: 1,
            email: '',
            last_name: '',
            first_name: '',
            password: '',
            confirm_password: '',
            remember_me: false,
            show_password: false,
            show_confirm_password: false,

            email_structure: /^\S+@\S+\.\S+$/,
            email_rules: [
                v => !!v || this.$t('login_account_component.email_required'),
                v => this.email_structure.test(v) || this.$t('login_account_component.email_not_valid'),
            ],
            password_structure: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            password_rules: [
                v => !!v || this.$t('login_account_component.password_required'),
                v => this.password_structure.test(v) || this.$t('login_account_component.password_rule_creation'),
            ],
            password_confirm_rules: [
                v => !!v || this.$t('login_account_component.confirm_password_required'),
                v => this.password === v || this.$t('login_account_component.password_do_not_match'),
            ],
            loading_register_account: false,
            loading_login: false,
        }
    },
    computed: {
        form_valid() {
            let result = this.email !== '' && this.email_structure.test(this.email) && this.password !== '';
            if (this.value_selected === 2) {
                result = result && this.first_name !== '' && this.last_name && this.confirm_password !== ''
                    && this.password_structure.test(this.password) && this.password === this.confirm_password;
            }
            return result;
        }
    },
    methods: {
        login() {
            this.loading_login = true;
            this.$auth.login({
                data: {
                    email: this.email,
                    password: this.password
                },
                staySignedIn: this.remember_me
            }).then((success) => {
                this.setAllInformationAfterConnect(success.data.data.id_professional, success.data.data);
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_login = false;
            });
        },

        register() {
            this.loading_register_account = true;

            UserClientRepository.postCreateAccount({
                id_display_option: this.$store.getters['display_option/display_option'].id_display_option,
                email: this.email,
                first_name: this.first_name,
                last_name: this.last_name,
                password: this.password,
                language: navigator.language
            }).then(() => {
                this.$emit('showAccountConfirmation');
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_register_account = false;
            });
        }
    }
}
</script>

<style>
.version-login-mobile {
    position: relative;
    top: 30px;
}
</style>
<template>
    <div id="account-confirmation-component">
        <v-card-title class="justify-center text-center">
            <v-img max-height="70" width="100%" class="mb-4"
                   :src="$store.getters['display_option/logo']" alt="Logo" contain
            />

            <strong v-if="!loading_verify_account">
                {{
                    page_account_activate ? $t('account_confirmation_component.account_confirm') : $t('account_confirmation_component.thanks_registration')
                }}
            </strong>
        </v-card-title>

        <v-card-text class="pb-0 text-center">
            <p class="text-center" v-if="!loading_verify_account">
                {{
                    page_account_activate ? $t('account_confirmation_component.account_confirmed') : $t('account_confirmation_component.email_send_inscription')
                }}
            </p>

            <v-progress-circular v-else indeterminate size="50" color="primary"/>
        </v-card-text>

        <v-card-actions>
            <v-btn large block color="primary secondary--text font-weight-bold"
                   v-if="page_account_activate && !loading_verify_account" @click="$emit('showLoginComponent')"
            >
                {{ $t('login_account_component.login') }}
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import SignUpLinkRepository from "@/repositories/SignUpLinkRepository";

export default {
    name: "AccountConfirmationComponent",
    data() {
        return {
            loading_verify_account: false
        }
    },
    props: {
        sign_up_link: {
            type: String,
        },
        page_account_activate: {
            type: Boolean,
            required: true
        }
    },
    created() {
        if (this.page_account_activate) {
            this.checkIfSignUpLinkExistByToken();
        }
    },
    methods: {
        checkIfSignUpLinkExistByToken() {
            this.loading_verify_account = true

            SignUpLinkRepository.getCheckIfSignUpLinkExistByToken(
                this.sign_up_link
            ).finally(() => {
                this.loading_verify_account = false;
            }).catch((error) => {
                this.manageError(error);
                this.$emit('showLoginComponent');
            });
        },
    }
}
</script>
